import React from 'react'
import Layout from 'src/components/layout'
import {pageGutters} from "../css/layout.module.css"
import {graphql} from 'gatsby'
import PostCollection from 'src/components/post-collection'
import Postcard from 'src/components/postcard'
import * as styles from 'src/css/case-studies-index.module.css'
import clsx from 'clsx'

const CaseStudiesIndex = ({data, pageContext}) => {
  const {heroArticles, featuredArticles, energy, finance, startUp, strapiCaseStudyIndex: {seo}} = data
  const {country} = pageContext || {}

  return (
    <Layout seo={seo} country={country}>
      <div className={clsx(pageGutters, styles.caseStudies)}>
        <h1>Case Studies</h1>
        <div className={styles.featuredSection}>
          <Postcard size="xl" layout="cover" post={heroArticles.nodes[0]} className={styles.heroPost} country={country} imgLoading="eager" />
          <div className={styles.featuredPosts}>
            {featuredArticles.nodes.map(post => (
              <Postcard size="lg" layout="cover" post={post} key={post.slug} className={styles.postcard} country={country} imgLoading="eager" />
            ))}
          </div>
        </div>
        <div className={styles.subjectSection}>
          <h2>Energy</h2>
          <PostCollection posts={energy.nodes} layout="cover" titleComponent="h3" className={styles.postcard} country={country} slider />
        </div>
        <div className={styles.subjectSection}>
          <h2>Finance</h2>
          <PostCollection posts={finance.nodes} layout="cover" titleComponent="h3" className={styles.postcard} country={country} slider />
        </div>
        <div className={styles.subjectSection}>
          <h2>Start Up</h2>
          <PostCollection posts={startUp.nodes} layout="cover" titleComponent="h3" className={styles.postcard} country={country} slider />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCaseStudyIndex {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    heroArticles: allStrapiArticle(
      filter: {postType: {slug: {eq: "case-studies"}}, topics: {elemMatch: {slug: {eq: "featured"}}}}
      sort: {fields: publishedAt, order: DESC}
      limit: 1 
    ) {
      nodes {
        ...PostcardAttributes
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1152
                height: 648
                transformOptions: {cropFocus: CENTER}
                placeholder: TRACED_SVG
              ) 
            }
          }
        }
      }
    }
    featuredArticles: allStrapiArticle(
      filter: {postType: {slug: {eq: "case-studies"}}, topics: {elemMatch: {slug: {eq: "featured"}}}}
      sort: {fields: publishedAt, order: DESC}
      skip: 1
      limit: 2
    ) {
      nodes {
        ...PostcardAttributes
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 564
                height: 310
                transformOptions: {cropFocus: CENTER}
                placeholder: TRACED_SVG
              ) 
            }
          }
        }
      }
    }
    energy: allStrapiArticle(
      filter: {postType: {slug: {eq: "case-studies"}}, topics: {elemMatch: {slug: {eq: "energy"}}}}
      sort: {fields: publishedAt, order: DESC} 
    ) {
      nodes {
        ...PostcardCover
      }
    }
    finance: allStrapiArticle(
      filter: {postType: {slug: {eq: "case-studies"}}, topics: {elemMatch: {slug: {eq: "finance"}}}}
      sort: {fields: publishedAt, order: DESC} 
    ) {
      nodes {
        ...PostcardCover
      }
    }
    startUp: allStrapiArticle(
      filter: {postType: {slug: {eq: "case-studies"}}, topics: {elemMatch: {slug: {eq: "start-up"}}}}
      sort: {fields: publishedAt, order: DESC} 
    ) {
      nodes {
        ...PostcardCover
      }
    }
  }
`;

export default CaseStudiesIndex